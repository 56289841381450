import './normalize.css';
import './skeleton.css';
import './App.css';
import axios from 'axios'
import { useState, useEffect } from 'react'


function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [emailInfo, setEmailInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  })
  const [showForm, setShowForm] = useState(true)
  const [showLogin, setShowLogin] = useState(false)
  const [admin, setAdmin] = useState({
    username: '',
    password: ''
  })
  const [authenticated, setAuthenticated] = useState(false)
  const [adminMessage, setAdminMessage] = useState('')
  const [blogPosts, setBlogPosts] = useState([])
  const [viewPost, setViewPost] = useState({
    _id: NaN,
    title: '',
    body: ''
  })
  const [showPrivacyInfo, setShowPrivacyInfo] = useState(false)
  const [showBlogUI, setShowBlogUI] = useState(false)
  const [blogInterface, setBlogInterface] = useState('list')
  const [currentPost, setCurrentPost] = useState({
    id: NaN,
    title: '',
    body: '',
    published: false
  })
  const [deleteDialogue, setDeleteDialogue] = useState(" ")

  axios.defaults.withCredentials = true

  //Hamburger menu toggle
  const toggleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  //email form input handler
  const handleChange = (event) => {
    setEmailInfo({...emailInfo, [event.target.name]: event.target.value})
  }
  
  const getBlogPosts = () => {
    axios.get('https://mtsvc-backend.onrender.com/blog')
      .then((response)=>{
        setBlogPosts(response.data)
      }, (err)=>{
        console.error(err)
        setBlogPosts(['Failed to load posts.'])
      })
  }

  //Client sending email via form
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Sending email: ', emailInfo)
    axios.post('https://mtsvc-backend.onrender.com/clients', emailInfo)
      .then(response => {
          setShowForm(false)
          setErrorMessage('')
        }, err => {
          console.error(err)
          setErrorMessage('Something went wrong, please check your information and try again. If the problem persists, please call instead.')
        }
      )
  }

  //handles credentials
  const handleAdminDetails = (event) => {
    setAdmin({...admin, [event.target.name]: event.target.value})
  }

  //toggles login form visibility
  const toggleLogin = () => {
    setShowLogin(!showLogin)
  }


  //handles login request to server
  const handleLogin = (event) => {
    event.preventDefault()
    setShowLogin(false)
    axios.put('https://mtsvc-backend.onrender.com/admin', admin)
      .then((response) => {
        if (response.data.authenticated === true){
          setAuthenticated(true)
          setAdmin({...admin, username: '', password: ''})
          setAdminMessage('')
        } else {
          setAdmin({...admin, username: '', password: ''})
          setAdminMessage('Bad Password.')
        }
      }, (error) => {
        setAuthenticated(false)
        setAdmin({...admin, username: '', password: ''})
        setAdminMessage('Login failed.')
      })
  }

  //handles logout request to server
  const handleLogout = () => {
    axios.delete('https://mtsvc-backend.onrender.com/admin')
      .then((response)=>{
        setAuthenticated(false)
      }, (error)=>{
        console.error(error)
        console.log('Something went wrong logging out.')
      })
  }

  //Shows/hides privacy info in footer
  const togglePrivacyInfo = () => {
    setShowPrivacyInfo(!showPrivacyInfo)
  }

  //shows/hides blog ui
  const toggleBlogUI = () => {
    setShowBlogUI(!showBlogUI)
  }

  //handles blog ui management
  const handleBlogInterface = (selection) => {
    setBlogInterface(selection)
  }

  //Sends new blog post info to server and adds the returned post to the BlogPosts state, sends the blog UI back to the main list
  const handleCreateBlogPost = (event) => {
    event.preventDefault()
    const newPost = {
      title: currentPost.title,
      body: currentPost.body,
      published: currentPost.published
    }
    axios.post('https://mtsvc-backend.onrender.com/blog', newPost)
      .then((response)=>{
        setBlogPosts([...blogPosts, response.data])
      })
    handleBlogInterface('list')
    setCurrentPost({
      id: NaN,
      title: '',
      body: '',
      published: false
    })
  }

  //sends updated blog post info to server
  const handleUpdateBlogPost = (event) => {
    event.preventDefault()
    const updatePost = {
      _id: currentPost._id,
      title: currentPost.title,
      body: currentPost.body,
      published: currentPost.published
    }
    console.log("Updated Blog Post being passed: ", updatePost)
    axios.put('https://mtsvc-backend.onrender.com/blog', updatePost)
      .then((response) => {
        if (response.data._id){
          const newPosts = [...blogPosts]
          newPosts.map((el, i)=>{
            if (el._id === response.data._id){
              newPosts[i] = {...response.data}
            } 
            return null
          })
          setBlogPosts(newPosts)
        } else {
          console.log(response.data)
        }
      }, (error) => {
        console.log(error)
      })
    handleBlogInterface('list')
    setCurrentPost({
      id: NaN,
      title: '',
      body: '',
      published: false
    })
  }

  const handleDeletePost = (event, post) => {
    event.stopPropagation();
    setDeleteDialogue(post._id)
  }

  const deletePost = (choice, post = null) => {
    if (choice) {
      axios.delete(`https://mtsvc-backend.onrender.com/blog/${post._id}`)
        .then((response)=>{
          if (response.data === "success"){
            setDeleteDialogue(" ")
            const newPosts = []
            blogPosts.map((el)=>{
              if (el._id !== post._id){
                newPosts.push(el)
              } 
              return null
            })
            setBlogPosts(newPosts)
          }
          console.log('Action rejected')
        }, (error)=>{
          console.error(error)
          alert("Something went wrong.")
          setDeleteDialogue(" ")
        })
    } else {
      setDeleteDialogue(" ")
    }
  }

  const handlePostSelect = (post) => {
    setCurrentPost(post)
    handleBlogInterface('update')
  }

  const handleNewPost = () => {
    setCurrentPost({id: NaN, title: '', body: '', published: false})
    handleBlogInterface('new')
  }

  const handleCurrentPostChange = (event) => {
    if (event.target.name !== 'published'){
      setCurrentPost({...currentPost, [event.target.name]: event.target.value})
    } else {
      if (event.target.checked){
        setCurrentPost({...currentPost, [event.target.name]: true})
      } else {
        setCurrentPost({...currentPost, [event.target.name]: false})
      }
    }
  }

  const resetCurrentPost = () => {
    handleBlogInterface('list')
    setCurrentPost({
      id: NaN,
      title: '',
      body: '',
      published: false
    })
  }

  const testAuth = () => {
    axios.get('https://mtsvc-backend.onrender.com/admin')
  }

  const openBlog = (post) => {
    setViewPost(post)
  }

  const resetViewPost = () => {
    setViewPost({
      _id: NaN,
      title: '',
      body: ''
    })
  }

  //Grabs blog data from server on load
  useEffect(()=>{
    getBlogPosts()
  }, [])

  return (
    <div className="container">
      <div className="row header-row u-full-width">
        <div id="header">
           <h1 id="main-title"><a id="top-link" href="#top">Margaret Tiner Speech & Voice Consultant</a></h1>
           <div className="nav-button" onClick={toggleShowMenu}><ion-icon name="menu-outline"></ion-icon></div>
        </div>
        {showMenu ?
        <>
          <div id="menu-modal" onClick={toggleShowMenu}>
          </div>
          <nav id="menu">
            <h3 id="menu-title">Menu</h3>
            <div id="menu-close" className="nav-button" onClick={toggleShowMenu}><ion-icon name="close-circle-outline"></ion-icon></div>
            <ul id="menu-list">
              <li><a className="menu-link" href="#about"><h4 className="menu-item">About</h4></a></li>
              <li><a className="menu-link" href="#areas-of-practice"><h4 className="menu-item">Areas of Practice</h4></a></li>
              <li><a className="menu-link" href="#bio"><h4 className="menu-item">Bio</h4></a></li>
              <li><a className="menu-link" href="#contact"><h4 className="menu-item">Contact</h4></a></li>
              <li><a className="menu-link" href="#schedule"><h4 className="menu-item">Schedule</h4></a></li>
              <li><a className="menu-link" href="#blog"><h4 className="menu-item">Blog</h4></a></li>
              <li><a className="menu-link" href="#other-information"><h4 className="menu-item">Other Information</h4></a></li>
            </ul>
          </nav>
        </>
          :
        null
        }        
      </div>
      <main id="top" >
        <div className="img-background">
          <h4 className="hero-text">Your speech is your brand</h4>
        </div>
        <h6 id="about" className="section-title">About</h6>
        <p>Margaret Tiner provides consulting and training to individuals who would like to improve their personal and professional speaking skills. Whether your goal is to inspire, motivate, or inform others, I can help you develop speaking skills that reflect your individual style and effectively deliver the message you intend to convey.  Let’s create your speaking brand.</p>
        <h6 id="areas-of-practice" className="section-title">Areas of Practice</h6>
        <ul id="areas-list">
          <li className="bullet-points">Professional speaking skills and style improvement</li>
          <li className="bullet-points">Speech, voice, articulation improvement</li>
          <li className="bullet-points">Accent modification</li>
        </ul>
        <h6 id="bio" className="section-title">Bio</h6>
        <p className="bio-container"><img id="headshot" src="./headshot.jpeg" alt="Headshot of Margaret Tiner"/>Margaret Tiner MS, CCC/SLP is a speech language pathologist whose practice focuses on improving an individuals personal and professional communication skills,   Her extensive experience includes work as a clinical speech-language pathologist in the area of voice, speech, articulation and resonance. She has helped individuals from a variety of personal and professional backgrounds improve their communucation abilities to achieve their goals.</p>
        
        <h6 id="contact" className="section-title">Contact</h6>
        {showForm ?
          <div >
            <form onSubmit={handleSubmit}>
              <label htmlFor="firstName">First Name:</label>
              <input type="text" name="firstName" placeholder="John" value={emailInfo.firstName} onChange={(event=>{handleChange(event)})} required></input>
              <label htmlFor="lastName">Last Name:</label>
              <input type="text" name="lastName" placeholder="Doe" value={emailInfo.lastName} onChange={(event)=>{handleChange(event)}} required></input>
              <label htmlFor="email">Please enter your email:</label>
              <input type="text" name="email" placeholder="email@example.com" value={emailInfo.email} onChange={(event)=>{handleChange(event)}} required></input>
              <label htmlFor="message">How can we help you?</label>
              <textarea name="message" placeholder="Your message here" value={emailInfo.message} onChange={(event)=>{handleChange(event)}} required></textarea>
              <button className="button-primary submit" type="submit">Submit</button>
              <span className="small">* See <a href="#other-information" className="small-link">other information</a> for privacy information</span>
            </form>
            {errorMessage !== '' ?
              <p className="error">{errorMessage}</p>
               :
              null
            }
          </div>
            :
          <div>
            <p>
              Thank you for your message!<br/>
              You should receive a confirmation email shortly.
            </p>
          </div>
        }
        <h6 id="schedule" className="section-title">Schedule</h6>
        <p>Please <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3pWUDc3JnNKEysb-Jdp9IH6fpefIz5FsORWQTSjLrdvTYJaOy6dkK55yCMqQl-5ZmcBiR6rxeD" target="_blank" rel="noreferrer">click here</a> to see our schedule availability and make an appointment. </p>
        <h6 id="blog" className="section-title">Blog</h6>
        {authenticated ?
          <button onClick={toggleBlogUI}>Blog Stuff</button>
          :
          null
        }
        {authenticated && showBlogUI ?
          <div id="blog-modal">
            <div id="blog-container">
              <h4>Blog Stuff</h4>
              {blogInterface === 'list' ?
              <div id="blog-list">
                <p>List of Blog Posts:</p>
                <div id="list-of-posts">
                {blogPosts.map((post, i)=>{
                  return (
                    <div className="post-card-container" key={("selectpost"+i)}>
                    {deleteDialogue !== post._id ?
                      <div className="post-card" onClick={()=>{handlePostSelect(post)}}>
                          <h5 className="blog-title-preview">{post.title}</h5>
                          <p className="blog-body-preview">{post.body}</p>
                          <div className="delete-post" onClick={(event)=>{handleDeletePost(event, post)}}><ion-icon name="trash-outline"></ion-icon></div>
                      </div>
                        :
                      <div className="delete-post-card">
                        <p>Are you sure you want to delete this post?</p>
                        <button className="button-primary cancel-delete" onClick={()=>{deletePost(false)}}>Cancel</button>
                        <button className="delete-confirm" onClick={()=>{deletePost(true, post)}}>Yes, delete this post</button>
                      </div>
                    }
                  </div>
                )
                })}
                </div>
                <button id="new-blog-button" className="button-primary" onClick={handleNewPost}>Start New Post</button>
              </div>
                :
              null
              }
              {blogInterface === 'new' ?
                <div>
                  <form onSubmit={(event)=>{handleCreateBlogPost(event)}}>
                    <label htmlFor="title">Title:</label>
                    <input className="blog-title-input" type="text" name="title" placeholder="Title" onChange={(event)=>{handleCurrentPostChange(event)}} value={currentPost.title}></input>
                    <label htmlFor="body">Body:</label>
                    <textarea className="blog-body-input" name="body" placeholder="Post body" onChange={(event)=>{handleCurrentPostChange(event)}} value={currentPost.body}></textarea>
                    <div className="checkbox-area">
                      <label htmlFor="published">Publish?</label>
                      <input type="checkbox" name="published"  onChange={(event)=>{handleCurrentPostChange(event)}} value={currentPost.published}></input>
                    </div>
                    <input className="button-primary" type="submit" value="Create"></input>
                  </form>
                  <button onClick={resetCurrentPost}>Back</button>
                </div>
                  :
                null            
              }
              {blogInterface === 'update' ? 
                <div>
                  <form onSubmit={(event)=>{handleUpdateBlogPost(event)}}>
                    <label htmlFor="title">Title:</label>
                    <input className="blog-title-input" type="text" name="title" placeholder="Title" onChange={(event)=>{handleCurrentPostChange(event)}} value={currentPost.title}></input>
                    <label htmlFor="body">Body:</label>
                    <textarea className="blog-body-input" name="body" placeholder="Post body" onChange={(event)=>{handleCurrentPostChange(event)}} value={currentPost.body}></textarea>
                    <div className="checkbox-area">
                      <label htmlFor="published">Publish?</label>
                      <input type="checkbox" name="published"  onChange={(event)=>{handleCurrentPostChange(event)}} checked={currentPost.published}></input>
                    </div>
                    <input className="button-primary" type="submit" value="Update"/>
                  </form>
                  <button onClick={resetCurrentPost}>Back</button>
                </div>
                  :
                null            
              }
              <div id="blog-close">
                <button className="button" onClick={toggleBlogUI}>Close</button>
                <button className="button" onClick={testAuth}>Test Auth</button>
              </div>
            </div>
          </div>
          :
          null
        }
        {blogPosts ?
          <>
            <div id="blog-posts-container">
              {blogPosts.map((post, i)=>{ return (
                post.published ?
                  <div key={("showpost-"+i)} onClick={()=>{openBlog(post)}} className="blog-card">
                    <h4 className="blog-card-title">{post.title}</h4>
                    <p className="blog-card-body">{post.body}</p>
                  </div>
                  :
                  null
                )
              })}
            </div>
            { viewPost._id ?
              <>
                <div id="viewer-modal" onClick={resetViewPost}>
                </div>
                <div id="blog-viewer">
                  <h2 >{viewPost.title}</h2>
                  <p>{viewPost.body}</p>
                  <button onClick={resetViewPost}>Close</button>
                </div>
              </>
                :
              null
            }
          </>
          :
          null
        }
      </main>
      <footer>
        <h6 id="other-information" className="section-title">Other Information</h6>
        <div className="footer-info">
          <div>
            <p id="privacy-button" onClick={togglePrivacyInfo}>
              Privacy Information
            </p>
            {showPrivacyInfo ?
              <p>When you submit the contact form, we collect your first name, last name, and email address for internal purposes. We do not share or sell your personal information in any form. We do not use your data with third party services. We do not use cookies or any other form of tracking information.</p>
              :
              null
            }
          </div>
          <div className="separator">
            <p>
              Margaret Tiner Speech & Voice Consultant, LLC
            </p>
            <p onClick={toggleLogin}>Admin Access</p>
            {showLogin ?
              <form onSubmit={(event)=>{handleLogin(event)}}>
                <input type="text" name="username" value={admin.username} onChange={(event)=>{handleAdminDetails(event)}} placeholder="Username" required></input>
                <input type="password" name="password" value={admin.password} onChange={(event)=>{handleAdminDetails(event)}} placeholder="Password" required></input>
                <button className="button">Login</button>
              </form>
                :
              <>
                {adminMessage ?
                  <p className="error">{adminMessage}</p>
                    :
                  null
                }
                {authenticated ?
                  <button className="button" onClick={handleLogout}>Logout</button>
                    :
                  null
                }
              </>
            }
            <p>
              All rights reserved, 2022 ®
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
